import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import anime from 'animejs';
import SupportUs from '../Components/SupportUs';

class Epilogue3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stopInstructionAnimation: false,
        };
        this.canvasRef = createRef();
        this.state = {
            canvasWidth: 0,
            canvasHeight: 0,
            preloadFont: 'd-inline',
            shareLink: '',
            shareDisplay: 'd-none',
            loading: true,
            canvasDisplay: 'd-none',
            menuDisplay: false,
            modalDisplay: 'd-none',
            shareModalDisplay: 'd-none',
        };
        this.flower_to_file = {
            Sunflower: 'sunflower1',
            Lilly: 'lilly1',
            Seed: 'seed1',
            Rose: 'rose1',
            Kluymai: 'kluymai1',
            Carnation: 'carnation1',
            Hydenyear: 'hydenyear1',
            Stattis: 'statis1',
        };
        this.override = `
            display: block;
            margin: auto auto;
            border-color: red;
        `;
        this.flower_map = {
            ideology: 'Sunflower',
            love: 'Rose',
            relationships: 'Hydenyear',
            live: 'Kluymai',
            dreams: 'Carnation',
            nothing: 'Stattis',
            'background-history': 'Lilly',
            'self-understanding': 'Seed',
        };
        // this.flower = this.flower_to_file[localStorage.getItem('flower')];
        const regretNotDoing = localStorage.getItem('regretNotDoing').split(':')[1];
        this.flower = this.flower_to_file[this.flower_map[regretNotDoing]];
        // this.closeModalListener = 1;
    }
    componentDidMount = () => {
        anime({
            targets: `#save-your-card`,
            duration: 500,
            opacity: 1,
            endDelay: 800,
            direction: 'alternate',
            loop: true,
            easing: 'easeInOutQuad',
            update: (anim) => {
                if (this.state.stopInstructionAnimation && anim.progress === 0) {
                    anim.pause();
                }
                if (!this.state.stopInstructionAnimation && anim.progress === 0) {
                    anim.play();
                }
            },
        });
        // this.updateWindowDimensions();
        // const resultArray = Object.keys(localStorage).map(function (key) {
        //     return `${key}: ${localStorage.getItem(key)}`;
        // });
        const resultArray = [`name: ${localStorage.getItem('name')}`, `age: ${localStorage.getItem('age')}`];
        console.log(resultArray);
        fetch('/api/append', {
            method: 'POST',
            body: JSON.stringify({ values: resultArray }),
            headers: { 'Content-Type': 'application/json' },
        });
        // window.addEventListener('resize', this.updateWindowDimensions);
        process.nextTick(() => {
            this.updateCanvas();
            this.setState({ preloadFont: 'd-none' });
            // this.share();
            this.setState({ shareDisplay: 'd-inline d-block' });
        });
    };
    componentWillUnmount = () => {
        // window.removeEventListener(this.closeModalListener);
    };

    updateCanvas = () => {
        const ctx = this.canvasRef.current.getContext('2d');
        var imageObj1 = new Image();
        //TODO แก้ตรงนี้
        imageObj1.src = `${process.env.PUBLIC_URL}/images/card/${this.flower}.png`;
        console.log(imageObj1);
        imageObj1.onload = () => {
            const imgWidth = 751;
            const imgHeight = 1051;
            ctx.canvas.width = imgWidth;
            ctx.canvas.height = imgHeight;
            ctx.drawImage(imageObj1, 0, 0, imgWidth, imgHeight);

            const fontPercent = 0.075;
            const topPercent = 0.185;
            ctx.font = `bold ${fontPercent * imgHeight}px PSLxThaiCommon`;
            const textString = localStorage.getItem('name');
            const textWidth = ctx.measureText(textString).width;
            ctx.fillText(textString, imgWidth / 2 - textWidth / 2, topPercent * imgHeight);
            this.setState({ loading: false, canvasDisplay: 'd-block' });
        };
    };

    saveCanvas = () => {
        if (!document.getElementById('image-save')) {
            var d = this.canvasRef.current.toDataURL('image/png');
            var element = document.getElementById('image-holder');
            element.innerHTML += "<img id='image-save' width='100%' src='" + d + "' alt='from canvas'/>";
        }
        if (this.state.modalDisplay === 'd-none') this.setState({ modalDisplay: 'd-block' });
        else this.setState({ modalDisplay: 'd-none' });
    };

    closeModal = () => {
        this.setState({ modalDisplay: 'd-none', shareModalDisplay: 'd-none' });
    };

    // share = async () => {
    //     console.log({
    //         name: localStorage.getItem('name'),
    //         flower: this.flower,
    //     });
    //     const regretNotDoing = localStorage.getItem('regretNotDoing').split(':')[1];
    //     this.flower = this.flower_map[regretNotDoing];
    //     let response = await fetch(`/generateThumbnail`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             name: localStorage.getItem('name'),
    //             flower: this.flower,
    //         }),
    //     });
    //     let resJson = await response.json();
    //     console.log(resJson);
    //     if (resJson) {
    //         const data = resJson.data;
    //         localStorage.setItem('imageLink', data.imageLink);
    //         localStorage.setItem('shareLink', data.shareLink);
    //         this.setState({ shareLink: data.shareLink });
    //     }
    // };

    shareToFB = async () => {
        this.setState({ shareModalDisplay: 'd-block' });
        // if (!this.state.shareLink) {
        //     this.setState({ loading: true });
        //     const regretNotDoing = localStorage.getItem('regretNotDoing').split(':')[1];
        //     const flower = this.flower_map[regretNotDoing];
        //     let response = await fetch(`/generateThumbnail`, {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({
        //             name: localStorage.getItem('name'),
        //             flower: flower,
        //         }),
        //     });
        //     let resJson = await response.json();
        //     console.log(resJson);
        //     if (resJson) {
        //         const data = resJson.data;
        //         localStorage.setItem('imageLink', data.imageLink);
        //         localStorage.setItem('shareLink', data.shareLink);
        //         this.setState({ shareLink: data.shareLink, loading: false });
        //         process.nextTick(() => {
        //             prompt('กรุณา copy ลิงค์นี้เพื่อแชร์ทางสื่อต่าง ๆ', this.state.shareLink);
        //         });
        //     }
        // } else {
        //     prompt('กรุณา copy ลิงค์นี้เพื่อแชร์ทางสื่อต่าง ๆ', this.state.shareLink);
        // }
        // window.FB.ui(
        //     {
        //         method: 'share',
        //         href: this.state.shareLink,
        //     },
        //     function (response) {
        //         console.log(response);
        //     }
        // );
    };

    validateEmail(mail) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(mail).toLowerCase());
    }

    saveResult = async () => {
        // let email = prompt(
        //     'โปรดระบุ E-mail ของคุณ เราจะส่งคำตอบของคุณไปให้ หากไม่เจอโปรดลองเช็ค Junk Mail ของคุณ',
        //     'foo@example.com'
        // );
        // if (this.validateEmail(email) && email !== 'foo@example.com') {
        //     let data = {};
        //     for (let [key, value] of Object.entries(localStorage)) {
        //         data[key] = value;
        //     }
        //     let response = await fetch(`/mailer`, {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({
        //             data: data,
        //             receiver: email,
        //         }),
        //     });
        //     alert('ระบบกำลังส่ง email ไปให้คุณ');
        //     let resJson = await response.json();
        //     console.log(resJson);
        //     if (resJson.response.includes('OK')) {
        //         alert('ลองเช็ค email ของคุณดู หากไม่เจอโปรดลองเช็คใน Junk Mail ดูก่อน');
        //     } else {
        //         alert(`เกิดข้อผิดพลาด ${resJson.response}`);
        //     }
        // } else {
        //     alert('โปรดใส่ email ให้ถูกต้อง');
        // }
    };

    summarizeLocalStorage = () => {
        const result = Object.keys(localStorage).map(function (key) {
            return [key + ' ' + localStorage.getItem(key)];
        });
        return result;
    };

    toggleMenu = () => {
        if (!this.state.stopInstructionAnimation) {
            this.setState({ stopInstructionAnimation: true });
        }
        this.setState({ stopInstructionAnimation: false });
        this.setState({ menuDisplay: !this.state.menuDisplay });
    };

    render() {
        return (
            <div className='position-relative'>
                <SupportUs />
                <div
                    className={`modal ${this.state.modalDisplay}`}
                    // tabindex='-1'
                    style={{ zIndex: 20 }}
                    role='dialog'
                    aria-labelledby='exampleModalCenterTitle'
                    aria-hidden='true'
                >
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content' id='exampleModalCenter'>
                            <div className='modal-header'>
                                <h5 className='modal-title text-24' id='exampleModalLongTitle'>
                                    กดรูปค้างไว้เพื่อบันทึก
                                </h5>
                                <button
                                    type='button'
                                    className='close text-24'
                                    onClick={this.closeModal}
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <div id='image-holder' />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal ${this.state.shareModalDisplay}`}
                    // tabindex='-1'
                    style={{ zIndex: 20 }}
                    role='dialog'
                    aria-labelledby='exampleModalCenterTitle'
                    aria-hidden='true'
                >
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content' id='exampleModalCenter'>
                            <div className='modal-header'>
                                <h5 className='modal-title text-24' id='exampleModalLongTitle'>
                                    กดรูปค้างไว้เพื่อบันทึก
                                </h5>
                                <button
                                    type='button'
                                    className='close text-24'
                                    onClick={this.closeModal}
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <div id='image-shareQR'>
                                    <img
                                        width='100%'
                                        src={process.env.PUBLIC_URL + '/images/shareQR/shareQR.jpg'}
                                        alt='shareQR'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column container prologue-screen bg-card position-relative'>
                    <div className='d-flex flex-column my-auto'>
                        <div
                            style={{ fontFamily: 'PSLxThaiCommon', margin: 'auto' }}
                            className={this.state.preloadFont}
                        >
                            กำลังประมวลผล
                        </div>
                        <BeatLoader css={this.override} size={15} color={'#9B9B9B'} loading={this.state.loading} />

                        <div className='text-24 mx-auto'>Follow us on 
                        <span> </span>
                        <span>
                            <a href='https://instagram.com/insidethesandbox.studio?utm_medium=copy_link' target='_blank' rel='noopener noreferrer'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='white' width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                            </a>
                        </span>
                        <span> </span>
                        <span>
                            <a href='https://www.facebook.com/deadlinealwaysexists' target='_blank' rel='noopener noreferrer'>
                                <svg xmlns="http://www.w3.org/2000/svg"  fill='white' width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg>
                            </a>
                        </span>
                        </div>
                        <canvas
                            ref={this.canvasRef}
                            style={{ width: '85%', height: '85%' }}
                            className={`m-auto pt-5 ${this.state.canvasDisplay}`}
                        />

                        {this.state.menuDisplay && (
                            <div className={`menu p-3`} style={{ zIndex: 1 }}>
                                <div>
                                    <button className={`clear-btn text-24`} onClick={this.saveCanvas}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icon/iconnnn-22.png'}
                                            alt='Save'
                                            className='menu-icon mr-3'
                                        />
                                        บันทึกรูป
                                    </button>
                                </div>
                                <div>
                                    <button className={`clear-btn text-24`} onClick={this.shareToFB}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icon/iconnnn-23.png'}
                                            alt='Share'
                                            className='menu-icon mr-3'
                                        />
                                        แชร์เว็บไซต์นี้ต่อ
                                    </button>
                                </div>
                                <div className={`clear-btn text-24`}>
                                    {/* <button className={`clear-btn text-24`} onClick={this.saveResult}> */}
                                    <a target='_blank' rel='noopener noreferrer' href='/email'>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icon/iconnnn-22.png'}
                                            alt='Save My Answer'
                                            className='menu-icon mr-3'
                                        />
                                        บันทึกคำตอบทั้งหมดของฉัน
                                    </a>
                                    {/* </button> */}
                                </div>
                                <svg width='100%' height='5' className='my-3 mx-3'>
                                    <rect
                                        width='80%'
                                        height='5'
                                        style={{ fill: 'rgb(0, 0, 0)', strokeWidth: 3, stroke: 'rgb(0, 0, 0)' }}
                                    />
                                </svg>
                                <div className={`clear-btn text-24`}>
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='https://www.facebook.com/Deadline-Always-Exists-103727901353329/?modal=admin_todo_tour'
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icon/iconnnn-25.png'}
                                            alt='Facebook Page'
                                            className='menu-icon mr-3'
                                        />
                                        ไปที่ Facebook ของโครงการ
                                    </a>
                                </div>
                                <div className={`clear-btn text-24`}>
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='https://issuu.com/deadlinealwaysexists-books/docs/fbelements-03'
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icon/iconnnn-24.png'}
                                            alt='Other Projects'
                                            className='menu-icon mr-3'
                                        />
                                        ดูชิ้นงานอื่นของโปรเจ็คนี้ต่อ
                                    </a>
                                </div>
                                <svg width='100%' height='5' className='my-3 mx-3'>
                                    <rect
                                        width='80%'
                                        height='5'
                                        style={{ fill: 'rgb(0, 0, 0)', strokeWidth: 3, stroke: 'rgb(0, 0, 0)' }}
                                    />
                                </svg>
                                <button className={`clear-btn text-24`} onClick={this.toggleMenu}>
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icon/iconnnn-27.png'}
                                        alt='close'
                                        className='menu-icon mr-3'
                                    />
                                    Close
                                </button>
                            </div>
                        )}
                        <div
                            id='save-your-card'
                            className='d-flex flex-column justify-content-end float-bottom-right'
                            style={{fontSize: 22, opacity: 0}}
                        >
                            <div className=''>
                                <button className={`clear-btn right-0`} onClick={this.toggleMenu}>
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icon/iconshare-28.png'}
                                        alt='save and share'
                                        // className='width-50px'
                                        style={{width: 30}}
                                    />
                                </button>
                            </div>
                            <div className=''>กดเพื่อบันทีกรูป</div>
                        </div>
                        <div className='text-24 float-top mx-auto'>#DeadlineAlwaysExists</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Epilogue3);
