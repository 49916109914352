import React, { Component } from 'react';
import anime from 'animejs';
import { withRouter } from 'react-router-dom';

class Warning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notRiskGroup: false,
        };
        this.animeJS = 0;
    }

    componentDidMount = () => {
        this.animeJS = anime.timeline();
        this.animeJS.add({
            targets: `.warning`,
            duration: 1500,
            opacity: 1,
            easing: 'linear',
            complete: () => {},
        });
        this.animeJS.add({
            targets: `.warning`,
            duration: 4500,
            opacity: 1,
            easing: 'linear',
            complete: () => {},
        });
        // this.animeJS.add({
        //     targets: `.warning`,
        //     duration: 1500,
        //     opacity: 0,
        //     easing: 'linear',
        //     complete: () => {
        //         this.props.history.push('/preface-transition');
        //     },
        // });
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        this.props.history.push('/preface-transition');
    };

    render() {
        return (
            <div
                className='d-flex  justify-content-center container prologue-screen'
                style={{ backgroundColor: 'white' }}
            >
                <div className='d-flex flex-column align-self-center  text-prescreen' style={{ color: '#0b0c0b' }}>
                    <div className='opacity-0 warning'>
                        <div className='text-center' style={{fontSize: '0.9em'}}>
                            เว็บไซต์นี้มีเนื้อหาเกี่ยวกับความตาย<br />
                            โปรดตรวจสอบความมั่นคงทางจิตใจของคุณก่อนเข้าเล่น
                        </div>
                        <div className='text-center mt-5' style={{fontSize: '0.9em'}}>
                            หากคุณมีความคิดถึงเรื่องความตาย<br />
                            หรือการทำร้ายตัวเองเกิดขึ้นบ่อยๆ ในช่วงนี้<br />
                            โปรดพิจารณาความเสี่ยงที่อาจเกิดขึ้นจากการเข้าชมเว็บไซต์
                        </div>
                        <div className='text-center mt-5' style={{fontSize: '0.9em'}}>
                            หากคุณรู้สึกไม่ดี สามารถหยุดเล่นได้ตลอดเวลา
                        </div>
                        <div className='text-center mt-5' style={{fontSize: '0.9em'}}>
                            และเราอยากให้รู้ไว้<br />
                            ไม่ว่าคุณจะเป็นใคร จะกดเล่นงานนี้หรือไม่<br />
                            คุณมีคุณค่าคู่ควรจะมีชีวิตอยู่เสมอ<br />
                            อย่าลืมรักษาใจตัวเองด้วยนะคะ
                        </div>
                        <button className='mt-6 mx-auto clear-btn d-flex justify-center' onClick={this.handleOnSubmit}>
                            <img
                                src={process.env.PUBLIC_URL + '/images/icon/iconlogo-12.png'}
                                alt='next'
                                className='next-icon'
                            />
                        </button>
                        {/* <br />
                        หากรู้สึกไม่ดีหลังเล่นเสร็จ
                        <br />
                        โปรดติดต่อคนสนิท หรือญาติ <br />
                        <a href='tel:1323'>หรือติดต่อสายด่วนสุขภาพจิต 1323</a> <br />
                        <a href='tel:027136793'>หรือสมาคมสะมาริตันส์แห่งประเทศไทย 02-713-6793</a> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Warning);
