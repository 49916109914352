import React from 'react';
import './SupportUs.css'

export default function SupportUs(props) {
  return (
    <div id="modal-container">
      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Support on Promptpay</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img
                src={process.env.PUBLIC_URL + '/promtpay.jpg'}
                alt='Promtpay QR code'
                className='w-100'
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="support-btn" data-toggle="modal" data-target="#exampleModal">
        ซัพพอร์ตให้เราทำโปรเจคต่อไป <span role="img" aria-label="a cup of coffee emoji" aria-labelledby="a cup of coffee emoji">☕</span>
      </button>
    </div>
  )
}