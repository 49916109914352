import React from 'react';


export const ClosingMask = () => {
  return (
    <button
      className='position-absolute mr-4 mt-4'
      style={{ background: 'transparent', border: 0, top: 0, right: 0, zIndex: 1 }}
      onClick={() => {
          if (
          window.confirm(
              'หากเล่นเว็บนี้แล้วรู้สึกไม่สบายใจ\nแล้วอยากได้คนช่วยรับฟัง\n\nสามารถติดต่อได้ที่เบอร์ 02-713-6793 (สมาคมสะมาริตันส์) / 1323 (สายด่วนสุขภาพจิต) ได้ตลอด 24 ชม.'
          )
          ) {
          window.location.href =
              'https://www.facebook.com/deadlinealwaysexists'
          }
      }}
      >
      <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
      >
          <path
          style={{ stroke: '#646464', strokeWidth: '2px' }}
          d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z'
          />
      </svg>
  </button>)
}